<template>
  <div>
    <b-card title="Todo 🚀">
      <h3>Settings</h3>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
};
</script>

<style></style>
